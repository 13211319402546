import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Link } from 'react-router-dom'; // Importa Link para navegação
import axios from 'axios';

const JetGPT = () => {
  const [inputText, setInputText] = useState('');
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);

  const handleSubmit = async () => {
    if (!inputText) {
      alert('Por favor, insira um texto.');
      return;
    }

    setLoading(true);
    setTableData([]);
    try {
      const response = await axios.post('/api/jetgpt/analyze', { text: inputText });
      setTableData(response.data.data); // Define os dados da tabela com o retorno do backend
    } catch (error) {
      console.error('Erro ao processar texto:', error);
      alert('Ocorreu um erro ao processar o texto.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        JetGPT - I.A.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Insira um texto no campo abaixo para análise.
      </Typography>

      {/* Text Area */}
      <TextField
        label="Texto para análise"
        multiline
        rows={6}
        fullWidth
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
        sx={{ marginBottom: 2 }}
      />

      {/* Botão de Enviar */}
      <Button variant="contained" onClick={handleSubmit} disabled={loading}>
        {loading ? <CircularProgress size={24} /> : 'Analisar Texto'}
      </Button>

      {/* Tabela Dinâmica */}
      {tableData.length > 0 && (
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h6" gutterBottom>
            Resultados da Análise
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                {/* Cria dinamicamente os cabeçalhos da tabela */}
                {Object.keys(tableData[0]).map((key) => (
                  <TableCell key={key}>{key}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Cria dinamicamente as linhas da tabela */}
              {tableData.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {Object.keys(row).map((key) => (
                    <TableCell key={key}>
                      {key.includes('cpf') > -1 ? (
                        <Link target='_blank' to={`/analyse-user/${row[key]}`}>{row[key]}</Link> // Adiciona o link se o campo for CPF
                      ) : (
                        row[key]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </Box>
  );
};

export default JetGPT;
