import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Paper,
  CircularProgress,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import axios from 'axios';
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const AnalyseUser = () => {
  const [vCpf, setCpf] = useState('');
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const intervalRef = useRef(null);
  const { cpf } = useParams();

  const formatCPF = (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2')
      .slice(0, 14);
  };

  const handleCpfChange = (e) => {
    const formattedCpf = formatCPF(e.target.value);
    setCpf(formattedCpf);
    clearInterval(intervalRef.current); 
  };

  let fetchRunning = false
  const fetchUserData = async (showLoader = true) => {

    if (fetchRunning) return

    const lCpf = vCpf || formatCPF(cpf)

    if (!lCpf) return;
    try {
      fetchRunning = true;
      if (showLoader) setLoading(true);
      const response = await axios.get(`/api/analyse/${lCpf}`);
      setUserData(response.data);
    } catch (error) {
      console.error('Erro ao buscar dados do usuário:', error.message);
    } finally {
      if (showLoader) setLoading(false);
      fetchRunning = false;
    }
  };

  useEffect(() => {
    if (cpf) {
      setCpf(formatCPF(cpf));
      fetchUserData();
    }
  }, [cpf]);

  useEffect(() => {
    if (userData) {
      intervalRef.current = setInterval(() => {
        fetchUserData(false); // Atualização transparente
      }, 30000); // Set interval to 30 seconds

      return () => clearInterval(intervalRef.current); // Cleanup interval on unmount or CPF change
    }
  }, [userData]);

  const formatNumber = (num) =>
    new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(num || 0);

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Análise de Usuário
      </Typography>

      <Box sx={{ marginBottom: 4 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={10}>
            <TextField
              label="CPF do Usuário"
              value={vCpf}
              onChange={handleCpfChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => fetchUserData()}
              disabled={!vCpf || loading}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : userData ? (
        <Box>
            
          <Grid container spacing={4}>
            <Grid item xs={24} md={2}>
                <Typography variant="h5" gutterBottom>
                    Dados do Cliente
                </Typography>
                <Paper sx={{ padding: 2, marginBottom: 4 }}>
                    <Typography><strong>Nome:</strong> {userData.clientData.moreInfos.nome} {userData.clientData.moreInfos.sobrenome}</Typography>
                    <Typography><strong>CPF:</strong> {userData.clientData.cpf}</Typography>
                    <Typography><strong>Telefone:</strong> {userData.clientData.telefone}</Typography>
                    <Typography><strong>Email:</strong> {userData.clientData.moreInfos.email}</Typography>
                    <Typography><strong>Cidade/UF:</strong> {userData.clientData.moreInfos['endereco[cidade_id]'].description} / {userData.clientData.moreInfos['endereco[estado_id]'].description}</Typography>
                    <Typography sx={{ color: 'blue' }}><strong>Saldo:</strong> {formatNumber(userData.clientData.saldo)}</Typography>
                    <Typography><strong>Nível:</strong> {userData.clientData.nivelCliente}</Typography>
                    <Typography><strong>Registro:</strong> {userData.clientData.dataRegistro}</Typography>
                    <Typography><strong>Último Login:</strong> {userData.clientData.ultimoLogin}</Typography>
                    <Typography><strong>Afiliado:</strong> <strong>{userData.clientData.moreInfos.afiliado || 'Não possui'}</strong></Typography>
                    {userData.clientData.moreInfos.afiliado ? (
                      <Typography>{userData.clientData.afiliado.afiliado_nome} ({userData.clientData.afiliado.afiliado_documento})</Typography>
                    ) : ''}
                </Paper>
            </Grid>
            <Grid item xs={24} md={2}>
                <Typography variant="h5" gutterBottom>
                    Limites
                </Typography>
                <Paper sx={{ padding: 2, marginBottom: 4 }}>
                    <Typography><strong>Diario Aposta:</strong> {userData.clientData.moreInfos['limite-diario-aposta']}</Typography>
                    <Typography><strong>Diário Depósito:</strong> {userData.clientData.moreInfos['limite-diario-deposito']}</Typography>
                    <Typography><strong>Mensal Aposta:</strong> {userData.clientData.moreInfos['limite-mensal-aposta']}</Typography>
                    <Typography><strong>Mensal Depósito:</strong> {userData.clientData.moreInfos['limite-mensal-deposito']}</Typography>
                    <Typography><strong>Semanal Aposta:</strong> {userData.clientData.moreInfos['limite-semanal-aposta']}</Typography>
                    <Typography><strong>Semanal Depósito:</strong> {userData.clientData.moreInfos['limite-semanal-deposito']}</Typography>
                </Paper>
            </Grid>
          
            <Grid item xs={24} md={4}>
                <Typography variant="h5" gutterBottom>
                    Resultado Financeiro
                </Typography>
                <Paper sx={{ padding: 2 }}>
                  <Typography
                    variant="h4"
                    sx={{ color: parseFloat(userData.cliente_movimento_financeiro_total[0].valor_depositos) - parseFloat(userData.cliente_movimento_financeiro_total[0].valor_saques) >= 0 ? 'blue' : 'red', }}
                  >
                    {`Lucro: ${formatNumber( parseFloat(userData.cliente_movimento_financeiro_total[0].valor_depositos) - parseFloat(userData.cliente_movimento_financeiro_total[0].valor_saques) )}`}
                  </Typography>
                  <Typography variant="h6">{`Depósitos: ${formatNumber(userData.cliente_movimento_financeiro_total[0].valor_depositos)}`}</Typography>
                  <Typography variant="h6">{`Saques: ${formatNumber(userData.cliente_movimento_financeiro_total[0].valor_saques)}`}</Typography>
                </Paper>          
            </Grid>

            <Grid item xs={24} md={4}>
                <Typography variant="h5" gutterBottom>
                    Resultado Apostas
                </Typography>
                <Paper sx={{ padding: 2 }}>
                  <Typography variant="h4" sx={{ color: parseFloat(userData.cliente_movimento_aposta_total[0].valor_liquido) >= 0 ? 'blue' : 'red', }}>
                      {`Lucro: ${formatNumber(parseFloat(userData.cliente_movimento_aposta_total[0].valor_liquido))}`}
                  </Typography>                  
                  <Typography variant="h6">{`Apostado: ${formatNumber(userData.cliente_movimento_aposta_total[0].valor_apostado)}`}</Typography>
                  <Typography variant="h6">{`Prêmios: ${formatNumber(userData.cliente_movimento_aposta_total[0].valor_premios)}`}</Typography>
                </Paper>          
            </Grid>
          </Grid>
          <Typography variant="h5" gutterBottom>
            Movimentações Financeiras
          </Typography>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={userData.cliente_movimento_financeiro.map((mov) => ({
              date: moment.utc(mov.date).format('DD/MM/YYYY'),
              saques: parseFloat(mov.total_saques),
              depositos: parseFloat(mov.total_depositos),
            }))}>
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip formatter={(value) => formatNumber(value)} />
              <Legend />
              <Bar dataKey="saques" fill="#8884d8" name="Saques" />
              <Bar dataKey="depositos" fill="#82ca9d" name="Depósitos" />
            </BarChart>
          </ResponsiveContainer>

          <Typography variant="h5" gutterBottom sx={{ marginTop: 4 }}>
            Movimentações de Apostas
          </Typography>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={userData.cliente_movimento_aposta.map((mov) => ({
              date: moment.utc(mov.date).format('DD/MM/YYYY'),
              totalApostado: parseFloat(mov.total_apostado),
              totalPremios: parseFloat(mov.total_premios),
              totalLiquido: parseFloat(mov.total_liquido),
            }))}>
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip formatter={(value) => formatNumber(value)} />
              <Legend />
              <Bar dataKey="totalApostado" fill="#ffc658" name="Total Apostado" />
              <Bar dataKey="totalPremios" fill="#82ca9d" name="Total Prêmios" />
              <Bar dataKey="totalLiquido" fill="#d45087" name="Total Líquido" />
            </BarChart>
          </ResponsiveContainer>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom sx={{ marginTop: 4 }}>
                Resumo Mensal Financeiro
            </Typography>
            <Paper sx={{ padding: 2, marginBottom: 4 }}>
                {userData.cliente_movimento_financeiro_meses.map((mes, index) => (
                <Box key={index}>
                    <Typography><strong>Mês:</strong> {moment.utc(mes.mes).format('MMMM [de] YYYY')}</Typography>
                    <Typography sx={{ color: parseFloat(mes.valor_depositos) - parseFloat(mes.valor_saques) >= 0 ? 'blue' : 'red', }}><strong>Lucro:</strong> {formatNumber( parseFloat(mes.valor_depositos) - parseFloat(mes.valor_saques) )}</Typography>
                    <Typography><strong>Saques:</strong> {formatNumber(mes.valor_saques)} ({mes.total_saques} operações)</Typography>
                    <Typography><strong>Depósitos:</strong> {formatNumber(mes.valor_depositos)} ({mes.total_depositos} operações)</Typography>
                    <Divider sx={{ my: 2 }} />
                </Box>
                ))}
            </Paper>

            </Grid>

            <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom sx={{ marginTop: 4 }}>
                Resumo Mensal de Apostas
            </Typography>
            <Paper sx={{ padding: 2, marginBottom: 4 }}>
                {userData.cliente_movimento_aposta_meses.map((mes, index) => (
                <Box key={index}>
                    <Typography><strong>Mês:</strong> {moment.utc(mes.mes).format('MMMM [de] YYYY')}</Typography>
                    <Typography sx={{ color: parseFloat(mes.valor_liquido) >= 0 ? 'blue' : 'red', }}><strong>Lucro:</strong> {formatNumber(mes.valor_liquido)}</Typography>
                    <Typography><strong>Apostado:</strong> {formatNumber(mes.valor_apostado)} ({mes.total_apostas} operações) </Typography>
                    <Typography><strong>Prêmios:</strong> {formatNumber(mes.valor_premios)}</Typography>
                    <Divider sx={{ my: 2 }} />
                </Box>
                ))}
            </Paper>
            </Grid>
            </Grid>

            {userData.ultimos_jogos.length > 0 && (
            <Box>
              <Typography variant="h6" gutterBottom>
                Jogados nas Últimas 48h
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    {/* Cria dinamicamente os cabeçalhos da tabela */}
                    {Object.keys(userData.ultimos_jogos[0]).map((key) => (
                      <TableCell key={key}><strong>{key}</strong></TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* Cria dinamicamente as linhas da tabela */}
                  {userData.ultimos_jogos.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {Object.keys(row).map((key) => (
                        <TableCell key={key}>{row[key]}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            )}
        </Box>
      ) : (
        <Typography>Insira um CPF e clique em buscar para visualizar os dados do jogador.</Typography>
      )}
    </Box>
  );
};

export default AnalyseUser;
