import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  TextField,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';

const Monitoramento = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]); // Inicializa data como um array vazio
  const [filters, setFilters] = useState({ filter: '', date: '' });

  // Fetch inicial de dados
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/api/aposta', {
        params: filters,
      });

      // Garante que o retorno é um array
      const responseData = response.data;
      setData(Array.isArray(responseData) ? responseData : []);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      setData([]); // Garante que data é um array mesmo em caso de erro
    } finally {
      setLoading(false);
    }
  };

  // Fetch inicial ao carregar a página
  useEffect(() => {
    fetchData();
  }, [filters]);

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Monitoramento de Atividades Suspeitas
      </Typography>

      {/* Filtros */}
      <Grid container spacing={2} sx={{ marginBottom: 4 }}>
        <Grid item xs={12} sm={6}>
          <TextField
            select
            label="Tipo de Filtro"
            fullWidth
            SelectProps={{
              native: true,
            }}
            value={filters.filter}
            onChange={(e) => setFilters({ ...filters, filter: e.target.value })}
          >
            <option value="">Todas as Apostas no Dia</option>
            <option value="highPayouts">Jogadores com Altos Prêmios</option>
            <option value="frequentPlayers">Jogadores com Alta Frequência</option>
            <option value="profitAnalysis">Análise de Lucros e Desempenho</option>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Data"
            type="date"
            fullWidth
            value={filters.date}
            onChange={(e) => setFilters({ ...filters, date: e.target.value })}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={fetchData}>
            Aplicar Filtros
          </Button>
        </Grid>
      </Grid>

      {/* Listas */}
      {loading ? (
        <CircularProgress />
      ) : data.length === 0 ? (
        <Typography>Nenhum dado encontrado.</Typography>
      ) : (
        data.map((list, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{list.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Apostador</TableCell>
                    <TableCell>CPF</TableCell>
                    <TableCell>Total de Apostas</TableCell>
                    {filters.filter === 'highPayouts' && <TableCell>Total de Prêmios</TableCell>}
                    {filters.filter === 'profitAnalysis' && (
                      <>
                        <TableCell>Total Apostado</TableCell>
                        <TableCell>Total de Prêmios</TableCell>
                        <TableCell>Total de Lucro</TableCell>
                        <TableCell>Média de Lucro</TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list.players?.map((player, idx) => (
                    <TableRow key={idx}>
                      <TableCell>{player.nome}</TableCell>
                      <TableCell>{player.cpf}</TableCell>
                      <TableCell>{player.totalApostas}</TableCell>
                      {filters.filter === 'highPayouts' && (
                        <TableCell>{player.totalPremios.toFixed(2)}</TableCell>
                      )}
                      {filters.filter === 'profitAnalysis' && (
                        <>
                          <TableCell>{player.totalValor.toFixed(2)}</TableCell>
                          <TableCell>{player.totalPremio.toFixed(2)}</TableCell>
                          <TableCell>{player.totalLucro.toFixed(2)}</TableCell>
                          <TableCell>{player.mediaLucro.toFixed(2)}</TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        ))
      )}
    </Box>
  );
};

export default Monitoramento;
