import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DashboardFinanceiro from './pages/DashboardFinanceiro';
import DashboardFinanceiroAposta from './pages/DashboardFinanceiroAposta';
import Monitoramento from './pages/Monitoramento';
import Home from './pages/Home'; // Importa a nova página
import JetGPT from './pages/JetGPT';
import AnalyseUser from './pages/AnalyseUser';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} /> {/* Página inicial */}
        <Route path="/financeiro-aposta" element={<DashboardFinanceiroAposta />} />
        <Route path="/monitoramento" element={<Monitoramento />} />
        <Route path="/financeiro" element={<DashboardFinanceiro />} />
        <Route path="/jet-gpt" element={<JetGPT />} />
        <Route path="/analyse-user/:cpf?" element={<AnalyseUser />} />
      </Routes>
    </Router>
  );
}

export default App;
