import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';

const Home = () => {
  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Bem-vindo ao Sistema
      </Typography>
      <Typography variant="body1" gutterBottom>
        Escolha uma das opções abaixo:
      </Typography>
      <List>
        <ListItem>
          <ListItemText>
            <Link to="/financeiro">Dashboard Financeiro</Link>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <Link to="/financeiro-aposta">Dashboard Financeiro - Apostas</Link>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <Link to="/jet-gpt">JetGPT - I.A.</Link>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            <Link to="/analyse-user">Análise Jogador</Link>
          </ListItemText>
        </ListItem>
      </List>
    </Box>
  );
};

export default Home;
